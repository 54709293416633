@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 16px;
}

body {
    min-height: 100vh;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    background-color: #efefef;
}

#root {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.App {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: 1px solid #333;
    box-shadow: 0px 0px 15px gray;
}

.Header,
.Footer {
    width: 100%;
    background-color: #66d8f5;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Header h1 {
    font-size: 1.5rem;
}

.Header svg {
    font-size: 2rem;
}

.Footer {
    padding: 0.75rem;
    display: grid;
    place-content: center;
}

.Nav {
    width: 100%;
    background-color: #333;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.searchForm {
    width: 80%;
    padding: 1rem 0 0 0.75rem;
}

.searchForm input[type='text'] {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    min-height: 48px;
    font-size: 1rem;
    padding: 0.25rem;
    border-radius: 0.25rem;
    outline: none;
}

.searchForm label {
    position: absolute;
    left: -99999px;
}

.Nav ul {
    color: #fff;
    list-style-type: none;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.Nav li {
    padding: 1rem;
}

.Nav li:hover,
.Nav li:focus {
    padding: 1rem;
}

.Nav li a {
    color: #fff;
    text-decoration: none;
}

.Nav li:hover,
.Nav li:focus,
.Nav li:hover a,
.Nav li:focus a {
    background-color: #eee;
    color: #333;
}

.Nav li:hover a,
.Nav li:focus a {
    cursor: pointer;
}

.Home,
.NewPost,
.PostPage,
.About,
.Missing {
    width: 100%;
    flex-grow: 1;
    padding: 1rem;
    overflow-y: auto;
    background-color: #fff;
}

.post {
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid lightgray;
}

.Home .post a {
    text-decoration: none;
}

.Home .post a,
.Home .post a:visited {
    color: #000;
}

.post:first-child {
    margin-top: 0;
}

.post:last-child {
    border-bottom: none;
}

.postDate {
    font-size: 0.75rem;
    margin-top: 0.25rem;
}

.postBody {
    margin: 1rem 0;
}

.newPostForm {
    display: flex;
    flex-direction: column;
}

.newPostForm label {
    margin-top: 1rem;
}

.newPostForm input[type='text'],
.newPostForm textarea {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    min-height: 48px;
    font-size: 1rem;
    padding: 0.25rem;
    border-radius: 0.25rem;
    margin-right: 0.25rem;
    outline: none;
}

.newPostForm textarea {
    height: 100px;
}

.newPostForm button {
    margin-top: 1rem;
    height: 48px;
    min-width: 48px;
    border-radius: 10px;
    padding: 0.5rem;
    font-size: 1rem;
    cursor: pointer;
}

.Missing h2,
.PostPage h2,
.Missing p,
.PostPage p {
    margin-bottom: 1rem;
}

.PostPage button {
    height: 48px;
    min-width: 48px;
    border-radius: 0.25rem;
    padding: 0.5rem;
    font-size: 1rem;
    background-color: red;
    color: #fff;
    cursor: pointer;
}

.statusMsg {
    margin-top: 2rem;
}

@media only screen and (min-width: 610px) {
    html {
        font-size: 22px;
    }

    .Header h1 {
        font-size: 2rem;
    }

    .Nav {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    }

    .Nav ul {
        text-align: right;
    }

    .Nav li:hover,
    .Nav li:focus,
    .Nav li:hover a,
    .Nav li:focus a {
        background-color: #eee;
        color: #333;
    }

    .searchForm {
        width: 50%;
        padding: 0.5rem 0;
    }

    .searchForm input[type='text'] {
        margin-left: 0.5rem;
    }

    .newPostForm textarea {
        height: 300px;
    }
}

@media only screen and (min-width: 992px) {
    .Header svg {
        font-size: 3rem;
    }
}
.create {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
}
.create label {
    text-align: left;
    display: block;
}
.create h2 {
    font-size: 50px;
    color: #090909;
    margin-bottom: 30px;
}
.create input,
.create textarea,
.create select {
    width: 100%;
    padding: 6px 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    box-sizing: border-box;
    display: block;
}
.create button {
    background: red;
    color: #fff;
    border: 0;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
}
